import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EventRow } from "../types";

export const EventDetailsView = (props: {
    close: () => void;
    events: Array<EventRow>
}) => {
    const params = useParams();
    const txId = params['transactionId'];
    const [fee, setFee] = useState<string>();
    const [resultDetails, setResultDetails] = useState<string>();
    const [description, setDescription] = useState<string>();

    useEffect(() => {
        const eventRow = props.events.find(t => t.event.id === txId);
        if (eventRow) {
            setFee(eventRow.fees);
            setResultDetails(eventRow.result.details);
            setDescription(
                ''// JSON.stringify(eventRow.event.facts, null, 2)
            );
        }
    }, [txId]);

    return <div className="d-flex flex-column">
        <legend>Event details</legend>
        <p>Transaction ID: {txId}</p>
        <div>Fee: {fee}</div>
        {resultDetails?.split('\n').map((text, idx) => <div key={idx}>{text}</div>)}
        <textarea cols={80} rows={30} value={description} contentEditable={false}></textarea>
        <div className="d-flex flex-row justify-content-end mt-3">
              <Button variant="secondary" onClick={props.close}>Close</Button>
            </div>
        </div>;
};