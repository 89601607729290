import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Assets, Balance, EventRow } from "../types";
import { ResultChart } from "./ResultChart";

const resultColor = (result?: string) => {
  return result?.startsWith('-') ? '#F0544F' : '#0681B1';
};

const OverviewCardsView = (props: {
    currency: string;
    assets: Assets;
    balances: Array<Balance>;
    dailyResults?: {
      lastDate: string;
      results: Array<number>;
    };
    hasSpecifiers: boolean;
    hasImportedTransactions: boolean;
    result: {
      trading?: string;
      income?: string;
      yield?: string;
    }
  }) => {
    const canHaveResults = props.hasImportedTransactions || props.hasSpecifiers;
    const [hideTip, setHideTip] = useState(canHaveResults);
    return !hideTip ? 
      <Alert className="d-flex flex-row justify-content-between align-self-center">
        <div>Import exchange transactions or manually specify transactions to compute financial results and track cost basis!</div>
        <button type="button" className="btn-close btn-close-white " onClick={() => setHideTip(true)} aria-label="Close"></button>
      </Alert>: 
      canHaveResults ? <div className="d-flex flex-column-reverse flex-lg-row">
        <div className="card card-body flex-shrink-0 d-flex flex-column flex-grow-1 align-items-stretch col-lg-3" style={{maxHeight: '200px'}}>
          <h5 className="card-title">1 Year</h5>
          <ResultChart dailyResults={props.dailyResults}/>
        </div>
        <div className="card flex-shrink-0 card-body flex-column mx-lg-3 col-lg-5" style={{maxHeight: '200px'}}>
          <h5 className="card-title">Asset Balances</h5>
          <div className="overflow-scroll flex-grow-1">
            {[['Coins', 'coin'], ['ERC20s', 'erc20'], ['NFTs', 'erc721'], ['SPL Tokens', 'spl']].map(([label, prefix]): [string, string, Array<Balance>] => {
              const filteredAndSortedBalances = 
                props.balances.filter((b) => b.quantity.quantity !== '0' && b.quantity.asset.startsWith(prefix))
                .sort((a, b) => props.assets[a.quantity.asset].code.localeCompare(props.assets[b.quantity.asset].code));
              return [label, prefix, filteredAndSortedBalances];
            })
            .filter(([_label, _prefix, balances]) => balances.length !== 0)
            .map(([label, prefix, balances]) => {
              return <div key={prefix} className="d-flex flex-column">
                 <table className="table table-borderless mb-1">
                  <thead>
                    <tr className="p-0">
                      <th scope="col" className="py-0"><small>{label}</small></th>
                      <th scope="col" className="py-0 text-end text-nowrap"><small>Balance</small></th>
                      <th scope="col" className="py-0 text-end text-nowrap"><small>{`Cost basis (${props.currency})`}</small></th>
                    </tr>
                  </thead>
                  <tbody>
                  {balances.map((b) => {
                    const asset = props.assets[b.quantity.asset].code;
                    return <tr key={b.quantity.asset}>
                      <td className="py-0 w-100">{`${asset.length > 30 ? asset.substring(0, 27) + '...' : asset}`}</td>
                      <td className="py-0 text-end text-nowrap">{`${b.quantity.quantity}`}</td>
                      <td className="py-0 text-end text-nowrap">{`${b.costBasis?.quantity ?? ''}`}</td>
                    </tr>;
                  })}
                  </tbody>
                  {/* {balances.map((b) => {
                    const asset = props.assets[b.quantity.asset].code;
                    return <div key={b.quantity.asset} className="d-flex flex-row justify-content-between">
                      <span className="flex-shrink-1">{`${asset.length > 30 ? asset.substring(0, 27) + '...' : asset}`}</span>
                      <span className="ms-3">{`${b.quantity.quantity}`}</span>
                    </div>; */}
                </table>
                </div>;
            })}
          </div>
        </div>
        <div className="card flex-shrink-0 card-body flex-column col-lg-3" style={{maxHeight: '200px'}}>
          <h5 className="card-title">Trading Result</h5>
          <div className="d-flex flex-column align-self-center align-items-stretch justify-content-evenly h-100 w-100">
            <h2 className="align-self-center" style={{color: resultColor(props.result.trading)}}>{props.result.trading}</h2>
            <div className="d-flex flex-row justify-content-evenly">
              <div className="d-flex flex-column align-items-center">
                <h6 className="mb-0">Income</h6>
                <strong style={{color: resultColor(props.result.income)}}>{props.result.income}</strong>
              </div>
              <div className="d-flex flex-column align-items-center">
                <h6 className="mb-0">Yield</h6>
                <strong style={{color: resultColor(props.result.yield)}}>{props.result.yield}</strong>
              </div>
            </div>
          </div>
        </div>
        </div> : <></>;
  }

  export default OverviewCardsView;