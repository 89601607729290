import React, { useEffect, useState } from 'react';
import ImportFileView from './ImportFileView';
import WalletsView from './WalletsView';
import SpecifyEventView from './SpecifyEventView';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Account, EventRow, Event, ChainID } from "../types";
import { AccountApi } from '../net/AccountApi';
import { EventDetailsView } from './EventDetailsView';
import ReportsView from './ReportsView';
import AccountOverviewView from './AccountOverviewView';


function AccountView(props: {
  accountApi: AccountApi
}) {
  const navigate = useNavigate();
  const [account, setAccount] = useState<Account>();
  const [updateStatus, setUpdateStatus] = useState<string | undefined>();
  const [overviewSettings, setOverviewSettings] = useState<{page: number, filter?: string}>({page: 1});
  const [accountAndPageEvents, setAccountAndPageEvents] = useState<{account: Account, events: Array<EventRow>, pages: number}>();
  const [pageEvents, setPageEvents] = useState<Array<EventRow>>(); // TODO: Get rid of this use, make views fetch individual events
  const [mergeWith, setMergeWith] = useState<{txId: string, otherSideFilter: (event: Event) => boolean, candidateId?: string}>();

  useEffect(() => {
    if (account == null) {
      return;
    }
    if (overviewSettings.page === 1 && overviewSettings.filter == null) {
      setAccountAndPageEvents({account, events: account.events, pages: account.pages});
      setPageEvents(account.events);
    } else {
      props.accountApi.getEvents(overviewSettings, ({events, pages}) => {
        setAccountAndPageEvents({account, events, pages});
        setPageEvents(events);
      });
    }
  }, [overviewSettings, account]);

  const updateAccount = (syncWallets?: boolean, next?: () => void) => {
    props.accountApi.getAccount(syncWallets ?? false, (account) => { 
        setAccount(account);
        next?.();
    })};

  useEffect(() => {
    props.accountApi.setUpdateListener(setUpdateStatus);
    updateAccount();
  }, [props.accountApi]);
  return account != null && updateStatus == null ? <Routes>
    <Route path="reports" element={<ReportsView 
        close={() => navigate('.')} 
        accountApi={props.accountApi}
     />} />
     <Route path="wallets" element={<WalletsView 
        close={() => navigate('.')} 
        wallets={account.wallets}
        balances={account.walletBalances}
        updateWallets={(wallets) => props.accountApi.updateWallets(wallets
            .filter(({chain}) => chain === ChainID.ETH)
            .map(({address}) => address),
            wallets
            .filter(({chain}) => chain === ChainID.SOL)
            .map(({address}) => address), 
            updateAccount)}
     />} />
     <Route path="import" element={<ImportFileView 
        custodians={account.custodians}
        lastImported={account.lastImportedTransactions}
        lastTsForWallets={account.lastTsForWallets}
        close={() => navigate('.')} 
        addCustodialTransactions={(custodianId, importedFileName, transactions: {version: number; rows: Array<Array<string>>}) => {
          props.accountApi.addCustodialTransactions(custodianId, importedFileName, transactions.version, transactions.rows, updateAccount);
        }}
        addSolscanTransactions={(transactions: {
          wallet: string, 
          columns: Array<string>,
          rows: Array<Array<string>>
        }) => {
          props.accountApi.addSolscanTransactions(transactions, updateAccount);
        }}
        deleteTransactions={(custodianId: string) => {
          props.accountApi.deleteCustodialTransactions(custodianId, updateAccount);
        }}
        />
      }/>

     <Route path="specify/:transactionId" element={<SpecifyEventView
        assets={account.assets}
        close={() => navigate('.')}
        accountApi={props.accountApi}
        specifiers={new Map(account.specifiers.map(({id, specifier}) => [id, specifier]))}
        specify={(id, data) => {
          props.accountApi.specifyEvent(id, data, () => {
            updateAccount(false, () => navigate('.'));
          });
        }}
        unspecify={(id) => {
          props.accountApi.unspecifyEvent(id, () => {
            updateAccount(false, () => navigate('.'));
          });
        }}
      />}/>
      <Route path="details/:transactionId" element={<EventDetailsView
        close={() => navigate('.')}
        events={pageEvents ?? []}
      />}/>
     <Route path="*" element={<AccountOverviewView 
        accountApi={props.accountApi} 
        page={overviewSettings.page} 
        filter={overviewSettings.filter}
        accountAndPageEvents={accountAndPageEvents ?? {account, events: [], pages: 1}}
        setPageSettings={(page, filter) => setOverviewSettings({page, filter})}
        syncWallets={() => updateAccount(true)}
        nextSyncTs={account.nextSyncTs}
        mergeWith={mergeWith}
        setMergeWith={setMergeWith}
        performMerge={(mergePairs: Array<string>) => props.accountApi.mergeTransactions(mergePairs, () => {
          setMergeWith(undefined);
          updateAccount();
        })}
        splitMerged={(txId: string) => props.accountApi.splitMerged(txId, updateAccount)}
        />} />
  </Routes> : 
  <><div className="d-flex flex-row align-items-center justify-content-center w-100 h-100">
      <div className="spinner-grow me-3" role="status"/>
      <div>{updateStatus}</div>
  </div></>;
}

export default AccountView;