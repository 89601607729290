import { capStringLength } from "../functions";
import { AssetQuantity, Assets, EarningsType, Event, EventType } from "../types";

function EventDescription(props: {event: Event, assets: Assets}) {
    let eventText;
    let descriptionStartNode;
    if (props.event.type === EventType.Convert) {
      if (props.event.price.length === 0) {
        eventText = 'Mint/claim fee for';
      } else {
        descriptionStartNode = <><span>{'Convert\u00A0'}</span>{
          getDisplayQuantities(props.event.price!, props.assets)
            .map((v, idx, arr) => <span className="text-nowrap">{v}{idx < arr.length - 1 ? ',\u00A0': ''}</span>)
          }<span>{'\u00A0to'}</span></>;
      }
    } else if (props.event.type === EventType.Transfer) {
      eventText = 'Transfer fee';
      if (props.event.quantity.length > 0) {
        eventText += ' for';
      }
    } else if (props.event.type === EventType.Error) {
      eventText = 'Invocation fee';
    } else if (props.event.type === EventType.Invocation) {
      eventText = 'Invocation fee';
    } else if (props.event.typeDesc != null) {
      eventText = props.event.typeDesc;
    } else if (props.event.type === EventType.Earnings && props.event.earningsType != null) {
      eventText = EarningsType[props.event.earningsType];
    } else {
      eventText = EventType[props.event.type];
    }
    if (props.event.hasInput) {
      if (props.event.type === EventType.Send) {
        eventText += ' to contract';
      }
      if (props.event.type === EventType.Receive) {
        eventText += ' from contract';
      }
    }
    descriptionStartNode = descriptionStartNode != null ? descriptionStartNode : <span className="text-nowrap">{eventText}</span>;
    return <span className="d-flex flex-row flex-wrap">{descriptionStartNode}
              {'\u00A0'} 
              {getDisplayQuantities(props.event.quantity, props.assets).map(
                (v, idx, arr) => <span className="text-nowrap">{v}{idx < arr.length - 1 ? ',\u00A0': ''}</span>)}
              {'\u00A0'}</span>;
}

const getDisplayQuantities = (quantities: Array<AssetQuantity>, assets: Assets) => {
    return quantities
        .map((qty) => {
        return `${qty.asset.startsWith('erc721:') ? 'NFT' : capStringLength(qty.quantity)} ${capStringLength(assets[qty.asset].code)}`;
        });
}

export default EventDescription;