import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip, FormCheck} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { capStringLength, prettyPrintTransactionId } from "../functions";
import { EventSpecifier, EventType, Event, Assets, AssetQuantity, EventRow, EarningsType, ExchangeRate, AssetID, ExchangeRateSource } from "../types";
import EventDescription from "./EventDescription";

const RateField = (props: {
  className?: string;
  rate: ExchangeRate,
  assets: Assets 
}) => {
  const assetOrCurrency = (id: AssetID) => {
    let code = props.assets[id]?.code;
    if ((code?.length ?? 0) > 9) {
      code = code.substring(0, 6) + '...';
    }
    return code ?? id.substring(id.lastIndexOf(':') + 1).toUpperCase();
  };
  return <OverlayTrigger key='rate-info'
    trigger='click'
    placement='top'
    overlay={
      <Tooltip id={`tooltip-rate-info`}>
        {`Rate from ${Object.keys(ExchangeRateSource)[Object.values(ExchangeRateSource).indexOf(props.rate.source)]} for 
          ${new Date(props.rate.ts).toISOString()} ${props.rate.source === ExchangeRateSource.ECB ? '(ECB only publishes rates on business days)' : ''}`}
      </Tooltip>
  }>
    <strong className={props.className}>{`${assetOrCurrency(props.rate.from)}/${assetOrCurrency(props.rate.to)}:${props.rate.rate}`}</strong>
  </OverlayTrigger>; 
}

const TransactionIdLink = (props: {
  className?: string;
  id: string;
}) => {
  const [txType, id1, id2] = props.id.split(':');
  switch(txType) {
    case 'eth':
      return <a className={props.className} href={`https://etherscan.io/tx/${id1}`} target="_blank">{prettyPrintTransactionId(props.id)}</a>;
    case 'sol':
      return <a className={props.className} href={`https://solscan.io/tx/${id1}`} target="_blank">{prettyPrintTransactionId(props.id)}</a>;
    case 'cust': {
      return <span className={(props.className ?? '') + ' text-nowrap'}>{`${id1 === 'cb' ? 'Coinbase': 'Unknown custodial'} transaction at ${new Date(parseInt(id2)).toISOString()}`}</span>;
    }
    default: {
      return <span className={props.className}>{'Unknown id: ' + props.id}</span>
    }
  }
};

const TransactionActions = (props: {
  tx: Event;
  specifier?: EventSpecifier;
  split: (txId: string, mergeId: string) => void;
  startMerge: (txId: string, otherSideFilter: (event: Event) => boolean) => void;
}) => {
  const [splitDialogShowing, setSplitDialogShowing] = useState(false);
  if (props.tx.specifyOptions == null && props.tx.mergeId == null) {
    return <></>;
  }
  if (props.tx.mergeId != null) {
    return <div>
      <a className="text-secondary" onClick={() => {
        setSplitDialogShowing(true);
      }}>Merged</a>
      <Modal show={splitDialogShowing}>
        <Modal.Header>
          <Modal.Title>Split merged transaction</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Split blockchain transaction {prettyPrintTransactionId(props.tx.id)} from custodial transaction  {prettyPrintTransactionId(props.tx.mergeId)}. The transaction
          will result in separate Send/Receive transactions.
            </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSplitDialogShowing(false)}>Cancel</Button>
          <Button variant="primary" onClick={() => {
            props.split(props.tx.id, props.tx.mergeId!);
          }}>Split</Button>
        </Modal.Footer>
      </Modal> : 
    </div>;
  }
  return <div>
        {props.tx.type === EventType.Send || props.tx.type === EventType.Receive ?
          <a className="text-dark" onClick={() => {
            props.startMerge(props.tx.id, (ev) => {
              const othersideType =  props.tx.type === EventType.Send ? EventType.Receive : EventType.Send;
              const assets = props.tx.quantity.map((aq) => aq.asset);
              return ev.type === othersideType && 
                ev.quantity.length === assets.length && 
                !ev.quantity.some((aq) => !assets.includes(aq.asset));
            });
          }}>Merge</a> : <></>}
        {props.tx.specifyOptions != null ?
        <Link className="ms-3" to={`specify/${props.tx.id}`}>
          {props.specifier != null ? <small className="text-secondary">Specified</small> : <span>Specify</span>}
        </Link> : <></>}
        </div>;
}

const ExpandCaret = (props: {
  isExpanded: boolean,
  setExpanded: (expanded: boolean) => void
}) => {
  return <div className="me-2" onClick={() => props.setExpanded(!props.isExpanded)}>
       {props.isExpanded ? <FontAwesomeIcon icon={["fas", "caret-down"]}/> : <FontAwesomeIcon icon={["fas","caret-right"]}/>}
  </div>;
}

const TransactionText = (props: {
  assets: Assets;
  row: EventRow;
  onClick: () => void;
  mergeWith?: {txId: string, otherSideFilter: (event: Event) => boolean, candidateId?: string}
  onMergeCandidateChange: (txId: string) => void
}) => {
  const isPotentialMerge = props.mergeWith != null && props.mergeWith.otherSideFilter(props.row.event);
  const [expanded, setExpanded] = useState(false);
  return <div className="d-flex flex-column">
        <div className="d-flex flex-column flex-md-row">
            <div className={"d-flex flex-row align-items-start " + (props.mergeWith != null && !isPotentialMerge && props.mergeWith?.txId !== props.row.event.id ? ' opacity-25' : '')}>
            <span>{isPotentialMerge || props.mergeWith?.txId === props.row.event.id ?
                <input type="checkbox" className="form-check-input me-1"
                  checked={props.mergeWith?.txId === props.row.event.id || props.mergeWith?.candidateId === props.row.event.id}
                  disabled={props.mergeWith?.txId === props.row.event.id}
                  onChange={(e) => props.onMergeCandidateChange(props.row.event.id)}/> :
                  <>
                  <OverlayTrigger key='icon-source-type'
                    trigger='click'
                    placement='right'
                    overlay={
                      <Tooltip id={`tooltip-source-type`}>
                        {props.row.event.mergeId != null ? 'Merge between a custodial and an on-chain transaction.' :
                          props.row.event.id.startsWith('cust:') ? 
                          'A transaction imported from a custodial account.' :
                          'An on-chain transaction.'}
                      </Tooltip>
                  }>
                  {props.row.event.mergeId != null ? 
                    <FontAwesomeIcon className="me-1" icon={["fas", "m"]} style={{minWidth: '20px'}}/> :
                    props.row.event.id.startsWith('cust:') ? 
                      <FontAwesomeIcon className="me-1" icon={["fas", "building"]} style={{minWidth: '20px'}}/> :
                      <FontAwesomeIcon className="me-1" icon={["fas", "link"]} style={{minWidth: '20px'}}/>}</OverlayTrigger>
                  </>}
              </span>
              <EventDescription event={props.row.event} assets={props.assets} />
              <ExpandCaret isExpanded={expanded} setExpanded={setExpanded} />
            </div>
        </div>
        {expanded? <div className="d-flex flex-column">
          <small><TransactionIdLink className="text-nowrap" id={props.row.event.id}/>{props.row.event.mergeId != null ? 
            <TransactionIdLink className="text-nowrap ms-3" id={props.row.event.mergeId}/> : <></>}
            {props.row.event.rates?.map((r, idx) => <RateField key={'rate'+idx} className="ms-3" rate={r} assets={props.assets} />)}</small>
          <small>{`Fees: ${props.row.fees}`}</small>
          {props.row.result.details?.split('\n').map((text, idx) => <small key={idx}>{text}</small>)}
        </div> : <></>}
        </div>;
}


function TransactionsView(props: {
  assets: Assets;
  transactionsByDate: Array<{date: string, events: Array<EventRow>}>;
  specifiers: Map<string, EventSpecifier>;
  mergeWith?: {txId: string, otherSideFilter: (event: Event) => boolean};
  startMerge: (txId: string, otherSideFilter: (event: Event) => boolean, candidateId?: string) => void;
  onMergeCandidateChange: (txId: string) => void;
  splitMerged: (txId: string) => void;
}) {
  const navigate = useNavigate();
  return <div className="overflow-scroll">
    <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col" className="pb-0 ps-0">Transaction</th>
            <th scope="col" className="pb-0"></th>
            <th scope="col" className="pb-0 text-end">Balance</th>
            <th scope="col" className="pb-0 text-end">Result</th>
          </tr>
        </thead>
        <tbody>
        {props.transactionsByDate.map(({date, events}) => {
      return <React.Fragment key={date}>  
        <tr className="table-light ps-0">
          <td colSpan={4} className="text-center ps-0"><strong>{date}</strong></td>
        </tr>
        {events.map(tx => {
          return (<tr key={tx.id}>
            <td className="w-100 ps-0">
              <TransactionText 
              assets={props.assets}
              row={tx} 
              onClick={() => {
                navigate(`details/${tx.event.id}`);
              }}
              mergeWith={props.mergeWith}
              onMergeCandidateChange={props.onMergeCandidateChange}
              />
          </td>
          <td className="text-nowrap text-end"> 
            {props.mergeWith != null ? <></> :
            <TransactionActions 
                tx={tx.event} 
                specifier={props.specifiers.get(tx.id)}
                startMerge={props.startMerge}
                split={props.splitMerged}
                /> }
            </td>
            <td className="d-flex flex-column text-nowrap text-end">
              {tx.balances.length > 0 ? tx.balances.map(({quantity, costBasis}) => {
                return <div className="d-flex flex-column" key={quantity.asset}>
                  <span>{`${quantity.quantity} ${capStringLength(props.assets[quantity.asset].code)}`}</span>
                  {costBasis == null ? <></> :
                  <small>{`paid ${costBasis.quantity} ${capStringLength(props.assets[costBasis.asset].code)}/${capStringLength(props.assets[quantity.asset].code)}`}</small>}
                </div>;
              }) : <div>{'\u00A0'}</div>}
            </td>
            <td className="text-nowrap text-end">
              {tx.result.value != null ? <small><b style={{color: tx.result.value.startsWith('-') ? '#F0544F' : '#0681B1'}}>{tx.result.value}</b></small> :
               <OverlayTrigger
                    key={tx.id}
                    placement={'bottom'}
                    overlay={
                      <Tooltip id={`tooltip-${tx.id}`}>
                        {tx.result.details}
                      </Tooltip>
                    }
                  >
                    <span> <FontAwesomeIcon icon={["fas", "info"]}/></span>
                  </OverlayTrigger>}
              </td>
          </tr>);
        })}
      </React.Fragment>;
    })}
        </tbody>
    </table>
    </div>
}

export default TransactionsView;