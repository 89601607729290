import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ACCOUNT_LIMITS, AssetQuantity, ChainID, Wallet } from "../types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WalletsList = (props: {
  wallets: Array<string>;
  setWallets: (wallets: Array<string>) => void;
  chain: ChainID;
  balances?: {[key in string]: AssetQuantity}
}) => {
  const [newAddress, setNewAddress] = useState('');
  return <>
      <InputGroup> 
        <Form.Control type="text" placeholder={props.chain === ChainID.ETH ? "0xab123..." : props.chain + ' Wallet Address'} value={newAddress} onChange={(e) => { setNewAddress(e.currentTarget.value); }} />
        <button className="btn btn-primary" type="button"
          disabled={props.wallets.length >= ACCOUNT_LIMITS.maxWallets || 
            (props.chain === ChainID.ETH && !/^0x([A-Fa-f0-9]{40})$/.test(newAddress)) || 
            (props.chain === ChainID.SOL && newAddress.length < 32)}
          onClick={(e) => {
            props.setWallets([newAddress].concat(props.wallets));
            setNewAddress('');
         }}>Add</button>
      </InputGroup>
      {props.wallets.length >= ACCOUNT_LIMITS.maxWallets ? <small>Max number of wallets for your plan reached</small> : <></>}
      <div className="mt-3">
      {props.wallets.map((w, idx) => <div key={w} className="mb-2">
        <InputGroup className="col-lg-3" >
          <pre className="mb-0 text-dark">{w}</pre>
          <a className="col col-lg-1 d-flex flex-row justify-content-end" onClick={(e) => {
            const copy = props.wallets.slice();
            copy.splice(idx, 1);
            props.setWallets(copy);
          }}><small><FontAwesomeIcon icon={["fas", "trash"]}/></small></a>
        </InputGroup>
        {props.balances != null ? <small>{`${props.balances[w]?.quantity ?? '--'} ${props.chain === ChainID.ETH ? 'ETH' : 'SOL'}`}</small>: <></>}
        </div>)}
        </div>
    </>;
}

export function ManageWallets(props: {
  balances?: {[key in string]: AssetQuantity}
  ethWallets: Array<string>;
  setEthWallets: (wallets: Array<string>) => void;
  solWallets: Array<string>;
  setSolWallets: (wallets: Array<string>) => void;
}) {
  return <>
    <h2>Ethereum</h2>
    <Form.Label>Public wallet address (starts with 0x)</Form.Label>
    <WalletsList wallets={props.ethWallets} setWallets={props.setEthWallets} chain={ChainID.ETH} balances={props.balances}/>
    <h2 className="mt-3">Solana</h2>
    <p>Note: You need to import Solana transactions manually as CSV files from Solscan exports.</p>
    <WalletsList wallets={props.solWallets} setWallets={props.setSolWallets} chain={ChainID.SOL} balances={props.balances}/>
  </>;
}

function WalletsView(props: {
  wallets: Array<Wallet>;
  balances: {[key in string]: AssetQuantity};
  updateWallets: (wallets: Array<Wallet>) => void;
  close: () => void;
  }) {
  const [ethWallets, setEthWallets] = useState(props.wallets.filter(({chain}) => chain === ChainID.ETH).map(({address}) => address));
  const [solWallets, setSolWallets] = useState(props.wallets.filter(({chain}) => chain === ChainID.SOL).map(({address}) => address));
  return <div className="d-flex flex-column">
    <div className="d-flex flex-row justify-content-between w-100">
      <legend>Wallets</legend>
    </div>
    <Form>
      <ManageWallets 
        balances={props.balances}
        ethWallets={ethWallets} 
        setEthWallets={setEthWallets}
        solWallets={solWallets} 
        setSolWallets={setSolWallets} />
    </Form>
    <div className="d-flex flex-row justify-content-end">
        <button className="btn btn-secondary" type="button" onClick={props.close}>Cancel</button>
        <button className="btn btn-primary ms-3" type="button"
          disabled={((ethWallets.length + solWallets.length) === props.wallets.length) && 
            props.wallets.every(({chain, address}) => chain === ChainID.ETH ? ethWallets.includes(address) : solWallets.includes(address))}
          onClick={() => {
            props.updateWallets(ethWallets
                .map((address) => {return {chain: ChainID.ETH, address}})
                .concat(solWallets.map((address) => {return {chain: ChainID.SOL, address}})));
            props.close();
        }}>Save</button>
      </div>
  </div>
}

export default WalletsView;