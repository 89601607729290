import React, { createContext, useEffect, useRef, useState } from 'react';
import { HashRouter, Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from './ErrorAlert';
import { Session, SessionContext } from './net/Session';
import { Connector, Connection } from './net/Connector';
import LoginOrRegister from './user/LoginOrRegister';
import { FinishLogin } from './user/FinishLogin';
import Profile from './user/Profile';
import { Api } from './net/Api';
import { StatusButton } from './user/StatusButton';
import AccountView from './accounts/AccountView';
import { NewAccountView } from './accounts/NewAccountView';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'


library.add(fas);

const UnauthenticatedContent = (props: {
  connector: Connector,
  reinitializeSession: () => void
}) => {
  const navigate = useNavigate();
  return <div className="container">
    <Routes>
        <Route path="/finishLogin" element={<FinishLogin connector={props.connector} onLoginSuccess={() => {
          navigate('/');
          props.reinitializeSession();
        }}/>} />
        <Route path="/register" element={<LoginOrRegister connector={props.connector} isRegister={true}/>} />
        <Route path="/login" element={<LoginOrRegister connector={props.connector} isRegister={false}/>} />
        <Route path="*" element={<div className="mt-4">
          <p>Not logged in. Please <Link to="/login">log in</Link> or <Link to="/register">sign up for a new account</Link></p>
          </div>}/>
      </Routes>
  </div>;
}; 

const SidebarMenu = (props: {accounts: Array<{id: string, name: string}>}) => {
  return <div className="d-flex flex-column mx-2">
    {props.accounts.map((a) => {
      return <div key={a.id} className="d-flex flex-column">
        <h6 className="mb-2 mt-2">{a.name}</h6>
        <small className="mb-1"><Link to={a.id}>Overview</Link></small>
        <small className="mb-1"><Link to={a.id + '/wallets'}>Wallets</Link></small>
        <small className="mb-1"><Link to={a.id + '/reports'}>Reports</Link></small>
        <small className="mb-1"><Link to={a.id + '/import'}>Import Transactions</Link></small>
      </div>;
    })}
  </div>;
}

const AccountViewWrapper = (props: {api: Api}) => {
  const { accountId } = useParams();
  return <AccountView accountApi={props.api.getAccountApi(accountId!)}/>
};

const AuthenticatedContent = (props: {
  api: Api
}) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Array<{id: string, name: string}>>([]);
  const runAfterAccountsChangeRef = useRef<() => void>(); // Defer redirect until after routes update
  const updateAccountList = (redirectToId?: string) => {
    props.api.listAccounts((newAccounts) => {
      if (redirectToId) {
        runAfterAccountsChangeRef.current = () => {
          navigate(redirectToId);
        };
      }
      setAccounts(newAccounts);
    });
  }
  useEffect(updateAccountList, []);
  useEffect(() => {
    if (runAfterAccountsChangeRef.current != null) {
      runAfterAccountsChangeRef.current();
      runAfterAccountsChangeRef.current = undefined;
    }
  }, [accounts]);
  return <div className="w-100">
    <div className="d-flex flex-column flex-lg-row">
      <SidebarMenu accounts={accounts} />
      <div className="container">
        <Routes>
          <Route path="/profile" element={<Profile/>} />
          {accounts.map((acc) => {
            return <Route key={acc.id} path={`/${acc.id}/*`} element={<AccountView accountApi={props.api.getAccountApi(acc.id)}/>} />;
          })}
          <Route key={'share'} path={`/share/:accountId`} element={<AccountViewWrapper api={props.api} />}/>
          <Route path="/" element={<>{accounts.length > 0 ? accounts.map(a => {
            return <div key={a.id}><Link to={a.id}>{a.name}</Link></div>;
          }): <NewAccountView api={props.api} newAccountCreated={(id) => {
            updateAccountList(id);
          }}/>}</>} />
        </Routes>
      </div>
    </div>
    <div className="w-100 my-5 d-flex flex-column align-items-center">
      <small>@ Copyright 2022 Updab AB. Contact: info@web3gains.com</small>
    </div>
  </div>;
}

function App() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const connector = new Connector(setErrorMessage);
  const [session, setSession] = useState<Session>();
  const connectionToSession = (connection: Connection) => {
    if (connection.isAuthenticated) {
      setSession(connection);
    } else {
      setSession(undefined);
    }
  };

  useEffect(() => {
    connector.initializeSession(connectionToSession);
    // setSession(MockSession);
  }, []);

  return (
    <HashRouter>
    <div className="d-flex flex-column">
      <header className="d-flex flex-row justify-content-between mb-2">
        <Link to="/"><img className="m-2" src="logo_w464_h100.png" style={{width: '186px', height: '40px'}}/></Link>
        <StatusButton signedInUser={session?.user.name} signOut={() => {
          session?.api.signOut(() => {
            connector.initializeSession(connectionToSession);
          });
        }}/>
      </header>
      <ErrorAlert message={errorMessage} dismissed={() => setErrorMessage(undefined)}/>
      {!session ? 
        <UnauthenticatedContent connector={connector} reinitializeSession={() => {
          connector.initializeSession((connection) => {
            connectionToSession(connection);
          });}} /> :
        <SessionContext.Provider value={session}>
          <AuthenticatedContent api={session.api} />
        </SessionContext.Provider>}
    </div>
    </HashRouter>
  );
}

const MockSession = {api: new Api(), user: { name: 'test', email: 'test@example.com' }};

export default App;


