import { useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap"
import { Api } from "../net/Api"
import { Currency } from "../types";
import { ManageWallets } from "./WalletsView";

const currencies = Object.keys(Currency).filter(item => isNaN(Number(item)));

export const NewAccountView = (props: {
    api: Api,
    newAccountCreated: (id: string) => void
}) => {
    const [name, setName] = useState('My Account');
    const [currency, setCurrency] = useState('USD');
    const [ethWallets, setEthWallets] = useState<Array<string>>([]);
    const [solWallets, setSolWallets] = useState<Array<string>>([]);
    return <Form className="d-flex flex-column">
    <legend>Create New Account</legend>
    <Form.Group className="d-flex pb-2 mb-3 border-bottom border-dark align-items-center" controlId="currency">
        <Form.Label className="flex-shrink-0">Account name</Form.Label>
        <Form.Control type="text" 
                className="ms-3"
                  placeholder="Price" 
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  />
    </Form.Group>
    <Form.Group className="d-flex flex-row pb-2 mb-3 border-bottom border-dark align-items-center" controlId="currency">
        <Form.Label>Local fiat currency</Form.Label>
        <DropdownButton
                  variant="outline-dark"
                  title={currency}
                  id="input-group-dropdown-4"
                  align="end"
                  className="flex-grow-0 ms-3"
                >
                  {currencies.map(c => <Dropdown.Item key={c} href="#" onClick={() => setCurrency(c)}>{c}</Dropdown.Item>)}
        </DropdownButton>
    </Form.Group>
    <ManageWallets ethWallets={ethWallets} setEthWallets={setEthWallets} solWallets={solWallets} setSolWallets={setSolWallets}  />
    <div className="d-flex flex-row justify-content-end mt-3">
        <Button variant="primary" className="ms-3" 
            type="button"
            onClick={() => {
                props.api.createAccount(name, currency, ethWallets, solWallets, (rsp: {id: string}) => {
                    props.newAccountCreated(rsp.id);
                });
        }}>Create</Button>
    </div>
  </Form>;
}