import { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AccountApi } from "../net/AccountApi";
import { EventType, EventSpecifier, Event, Currency, EarningsType, SpecifyOptions, AssetQuantity, Assets } from "../types";
import EventDescription from "./EventDescription";

const currencies = Object.keys(Currency).filter(item => isNaN(Number(item))).sort();

export const isNumberEntireString = (s: string): boolean => {
  return !isNaN(s as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(s)); // ...and ensure strings of whitespace fail
}

function SectionWithPrice(props: {
  label: string;
  disabled: boolean;
  price: string;
  currency: string;
  setPrice: (price: string) => void;
  setCurrency: (currency: string) => void;
}) {
  return <div className="d-flex flex-column ms-2">
            <Form.Group>
              <Form.Label>{props.label}</Form.Label>
              <InputGroup className="mb-3" hasValidation>
                <Form.Control type="text" 
                  placeholder="Price" 
                  disabled={props.disabled} 
                  value={props.price}
                  onChange={(e) => props.setPrice(e.currentTarget.value)}
                  isInvalid={!props.disabled && !isNumberEntireString(props.price)}
                  />
                <DropdownButton
                  variant="outline-dark"
                  title={props.currency}
                  id="input-group-dropdown-4"
                  align="end"
                  className="flex-grow-0"
                  disabled={props.disabled}
                >
                  {currencies.map(c => <Dropdown.Item key={c} href="#" onClick={() => props.setCurrency(c)}>{c}</Dropdown.Item>)}
                </DropdownButton>
                <Form.Control.Feedback type="invalid">Must be a number</Form.Control.Feedback>

              </InputGroup>
            </Form.Group>
          </div>;
}

const Specifiers: {
  [key in string]: {
    availableIn: Array<SpecifyOptions>;
    eventType: EventType;
    earningsType?: EarningsType;
  }
} = {
  Buy: {
    availableIn: [SpecifyOptions.Receive],
    eventType: EventType.Buy
  },
  Sell: {
    availableIn: [SpecifyOptions.Send],
    eventType: EventType.Sell
  },
  Transfer: {
    availableIn: [SpecifyOptions.Receive, SpecifyOptions.Send, SpecifyOptions.ZeroPriceConvert],
    eventType: EventType.Transfer
  },
  Spam: {
    availableIn: [SpecifyOptions.Receive],
    eventType: EventType.Earnings,
    earningsType: EarningsType.Spam
  },
  Yield: {
    availableIn: [SpecifyOptions.Receive],
    eventType: EventType.Earnings,
    earningsType: EarningsType.Yield
  },
  Income: {
    availableIn: [SpecifyOptions.Receive],
    eventType: EventType.Earnings,
    earningsType: EarningsType.Income
  },
  'Free mint/claim': {
    availableIn: [SpecifyOptions.ZeroPriceConvert],
    eventType: EventType.Convert
  }
};

function SpecifyViewWithLoadedEvent(props: {
  cancel: () => void;
  event: Event;
  assets: Assets;
  specifier?: EventSpecifier;
  specify: (data: EventSpecifier) => void;
  unspecify: () => void;
}) {
  const [selected, setSelected] = useState<keyof typeof Specifiers | undefined>(
    Object.entries(Specifiers).find(([k, v]) => {
      return v.availableIn.includes(props.event.specifyOptions!) && 
        v.eventType === props.event.type && 
        v.earningsType === props.event.earningsType;
    })?.[0] as keyof typeof Specifiers ?? undefined);
  const [validSpecifier, setValidSpecifier] = useState(props.specifier);
  const anyPrice = props.specifier?.price as any;
  const specifierPrice: AssetQuantity = anyPrice != null && 'asset' in anyPrice ? anyPrice : anyPrice?.[0];
  const [price, setPrice] = useState(specifierPrice?.quantity ?? '0.0');
  const [priceCurrency, setPriceCurrency] = useState(specifierPrice?.asset ?? 'USD');
  const priceOptionKind = props.event.specifyOptions === SpecifyOptions.Receive ? 'Buy' : 
    props.event.specifyOptions === SpecifyOptions.Send ? 'Sell' : undefined;
  const otherOptions: Array<keyof typeof Specifiers> = 
    props.event.specifyOptions === SpecifyOptions.Send ? ['Transfer'] :
    props.event.specifyOptions === SpecifyOptions.ZeroPriceConvert ? ['Free mint/claim', 'Transfer'] :
    props.event.specifyOptions === SpecifyOptions.Receive ? ['Transfer', 'Spam', 'Yield', 'Income'] :
    []; 
  const transferDirection = props.event.specifyOptions === SpecifyOptions.Receive ||
    props.event.specifyOptions === SpecifyOptions.ZeroPriceConvert ? 'from' : 'to'

  useEffect(() => {
    if (selected != null) {
      const specifier = Specifiers[selected];
      const spec: EventSpecifier = {
        type: specifier.eventType
      };
      spec.earningsType = specifier.earningsType;
      if (selected === 'Free mint/claim') {
        spec.price = [];
      } else if (specifier.eventType === EventType.Buy || specifier.eventType === EventType.Sell) {
        spec.price = [{ quantity: price, asset: priceCurrency }];
      }
      setValidSpecifier(spec);
    }
  }, [selected, price, priceCurrency]);

  return <Form className="d-flex flex-column">
          <legend>Specify Transaction</legend>
          <div className="my-2">
            Specifying: <EventDescription event={props.event} assets={props.assets} />
          </div>
          <p><strong>Tip:</strong> Transactions can be automatically specified by <Link to="../import">importing transactions</Link> from your custodial accounts.</p>
          {priceOptionKind != null ?
            <Form.Group className={"d-flex flex-row p-2 mb-3 border" + (selected === priceOptionKind ? ' border-primary' : '') } controlId={priceOptionKind}>
              <Form.Check 
                  type="radio"
                  key={priceOptionKind} 
                  id={`toggle-${priceOptionKind}`} 
                  checked={selected === priceOptionKind}
                  value={priceOptionKind} 
                  name='txkinds' 
                  onChange={(e) => {
                    if (e.currentTarget.checked === true) {
                      setSelected(priceOptionKind);
                    }
                  }}
                  />
              <SectionWithPrice label={priceOptionKind} 
                disabled={selected !== priceOptionKind} 
                price={price} 
                setPrice={setPrice} 
                currency={priceCurrency} 
                setCurrency={setPriceCurrency} />
            </Form.Group> :
            ''
          }
          {otherOptions.map((opt) => {
            return <Form.Group 
              key={opt} 
              className={"mb-3 d-flex flex-row p-2 border" + (selected === opt ? ' border-primary' : '')} controlId={opt}>
              <Form.Check 
                  type="radio"
                  id={`toggle-${opt}`} 
                  checked={selected === opt}
                  value={opt} 
                  name='txkinds' 
                  label=""
                  onChange={(e) => {
                    if (e.currentTarget.checked === true) {
                      setSelected(opt);
                    }
                  }}
                  />
              <Form.Label>{opt !== 'Transfer' ? opt : `Transfer ${transferDirection} own holding`}</Form.Label>
            </Form.Group>;
          })}
          <div className="d-flex flex-row justify-content-end mt-3">
            
            <Button variant="secondary" onClick={props.cancel}>Cancel</Button>
            {props.specifier != null ?
            <Button variant="primary" className="ms-3" onClick={() => {
              props.unspecify();
            }}>Unspecify</Button>
             : <></>}
            <Button variant="primary" className="ms-3" 
              type="button"
              disabled={validSpecifier == null}
              onClick={() => {
                props.specify(validSpecifier!);
            }}>Specify</Button>
          </div>
        </Form>;
}

function SpecifyEventView(props: {
  close: () => void;
  assets: Assets;
  accountApi: AccountApi,
  specifiers: Map<string, EventSpecifier>;
  specify: (id: string, data: EventSpecifier) => void;
  unspecify: (id: string) => void;
}) {
  const params = useParams();
  const [specifier, setSpecifier] = useState<EventSpecifier>();
  const [event, setEvent] = useState<Event>();
  useEffect(() => {
    const txId = params['transactionId'];
    if (txId != null) {
      setSpecifier(props.specifiers.get(txId) );
      props.accountApi.getEvent(txId, setEvent);
    } else {
      setEvent(undefined);
      setSpecifier(undefined);
    }
  }, [params]);

  return event == null ? <div></div> : 
  <SpecifyViewWithLoadedEvent cancel={props.close} 
    assets={props.assets}
    event={event} 
    specifier={specifier} 
    unspecify={() => {
      props.unspecify(event.id);
    }} 
    specify={(data) => {
      props.specify(event.id, data);
    }} />;
}

export default SpecifyEventView;