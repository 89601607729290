import { AssetQuantity, Assets, EventRow, EventType } from "./types";

export const prettyPrintTransactionId = (id: string): string => {
    const n = id.lastIndexOf(':');
    if (id.startsWith('cust:')) {
        return `Coinbase ${new Date(parseInt(id.substring(n + 1))).toISOString()}`;
    } else if (id.startsWith('eth:')) {
        return `Ethereum ${id.substring(n + 1, n + 6)}...${id.substring(id.length - 4)}`
    } else if (id.startsWith('sol:')) {
        return `Solana ${id.substring(n + 1, n + 6)}...${id.substring(id.length - 4)}`
    } else {
        return id;
    }
}
  
export const capStringLength = (name: string) => {
    if (name.length >= 15) {
        return name.substring(0, 12) + '...';
    }
    return name;
}