import Papa from "papaparse";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { ChainID, Custodian, Wallet } from "../types";

const coinbaseCsvToRows = (columns: Array<string>, data: string): {
    rows: Array<Array<string>>;
    parsingErrors: Array<string>;
  } => {
  const result = Papa.parse(data, {header: true, skipEmptyLines: 'greedy', beforeFirstChunk: (chunk) => {
    // Find the first header line
    const n = chunk.indexOf("\nID,");
    if (n >= 0) {
      return chunk.substring(n + 1);
    }
    return chunk;
  }});
  const parsingErrors: Array<string> = [];
  const rows =  (result.data as Array<{[key in string]: string}>)
    .flatMap((line) => {
      const errorsBefore = parsingErrors.length;
      const lineValues = columns.map(c => {
        const value = line[c];
        if (value != null) {
          return value;
        } else {
          parsingErrors.push('Column ' + c + ' not found.');
          return '';
        }
      });
      if (errorsBefore === parsingErrors.length) {
        return [lineValues];
      } else {
        return [];
      }
    });
    return {
      rows,
      parsingErrors
    };
}

const solscanColumns = [
  'Type',
  'Txhash',
  'BlockTime Unix',
  'BlockTime',
  'Fee (SOL)',
  'TokenName(off-chain)',
  'Symbol(off-chain)',

  'TokenAccount',
  'ChangeType',
  'SPL BalanceChange',
  'PreBalancer',
  'PostBalancer',
  'TokenAddress',

  'SolTransfer Source',
  'SolTransfer Destination',
  'Amount (SOL)',
];

const solscanCsvToRows = (data: string): {
  rows: Array<Array<string>>;
  parsingErrors: Array<string>;
} => {  
const result = Papa.parse(data, {header: true, skipEmptyLines: 'greedy', beforeFirstChunk: (chunk) => {
  // Find the first header line
  const n = chunk.indexOf("\n,");
  if (n >= 0) {
    return chunk.substring(n + 1);
  }
  return chunk;
}});
const parsingErrors: Array<string> = [];
const rows =  (result.data as Array<{[key in string]: string}>)
  .flatMap((rawline) => {
    const line = Object.fromEntries(Object.entries(rawline).map(([k, v]) => [k.trim(), v]));
    const errorsBefore = parsingErrors.length;
    const lineValues = solscanColumns.map(c => {
      const value = line[c];
      if (value != null) {
        return value;
      } else {
        parsingErrors.push('Column ' + c + ' not found.');
        return '';
      }
    });
    if (errorsBefore === parsingErrors.length) {
      return [lineValues];
    } else {
      return [];
    }
  });
  return {
    rows,
    parsingErrors
  };
}


const ImportFileView = (props: {
  custodians: Array<Custodian>;
  close: () => void;
  lastImported: Array<{
    custodian: string;
    ts: number;
  }>;
  lastTsForWallets: Array<{
    wallet: Wallet;
    ts: number;
  }>;
  addCustodialTransactions: (custodianId: string, fileName: string, transactions: {
    version: number;
    rows: Array<Array<string>>
  }) => void;
  deleteTransactions: (custodianId: string) => void;
  addSolscanTransactions: (transactions: {
    wallet: string, 
    columns: Array<string>,
    rows: Array<Array<string>>
  }) => void;
}) => {
  const [importType, setImportType] = useState<string>();
  const custodian = props.custodians.find((c) => c.id === 'cb')!;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [importedFileName, setImportedFileName] = useState('');
  const [transactions, setTransactions] = useState<Array<Array<string>>>();
  const [statusMessage, setStatusMessage] = useState<string>();
  const [solscanWallet, setSolscanWallet] = useState<string>();
  return <div className="d-flex flex-column">
    <div className="d-flex flex-row justify-content-between w-100">
    </div>
      <Form>
      <legend>Import Transactions</legend>
      <p>Importing transactions from custodial accounts, such as at crypto exchanges, is the quickest way to compute cost basis for your assets.</p>
      {importType == null ? <Form.Group>
        <Form.Label>Import From</Form.Label>
        <Form.Select onChange={(e) => { 
          setImportType(e.currentTarget.value);
          }}>
          <option>Select an import source</option>
          <option value="custodian:cb">Coinbase</option>
          <option value="explorer:solscan">Solscan</option>
        </Form.Select>
      </Form.Group> : <></>}
      {importType === 'custodian:cb' ?
        <div>
        <Form.Group>
          <h2>Import from Coinbase</h2>
          <Form.Label>Export a Coinbase <a href="https://help.coinbase.com/en/commerce/managing-account/transaction-reporting" target="_blank">transaction report</a> as a CSV file and upload it here</Form.Label>
          <Form.Control type="file" onChange={(e) => {
            const file: File = (e.target as any)?.files?.[0];
            if (file != null) {
              const reader = new FileReader();
              reader.readAsText(file, "UTF-8");
              reader.onload = (value) => {
                const result = coinbaseCsvToRows(custodian.csvFormat.columns, reader.result as string);
                const lastImportedTs = props.lastImported.find(i => i.custodian === 'cb')?.ts;
                setImportedFileName(file.name);
                if (result.parsingErrors.length > 0) {
                  setErrorMessage(result.parsingErrors.join(', '));
                }
                const txs = result.rows.filter(row => {
                  return lastImportedTs == null || 
                    new Date(row[custodian.csvFormat.timestampColumnIndex]).getTime() > lastImportedTs
                });
                setTransactions(txs);
                setStatusMessage(`Read ${txs.length} new transactions`);
              };
              reader.onerror = (value) => {
                setImportedFileName('');
                setStatusMessage(undefined);
                setTransactions(undefined);
                setErrorMessage(errorMessage);
              }
            }
          }}/>
        </Form.Group>
        <div className="mt-4">
        <h5>Delete imported transactions</h5>
        <Form.Label>
        Only transactions that are newer than in the last import get imported, but you can also delete all previously imported transactions before importing.
        </Form.Label>
        <div>
        <button className="btn btn-primary"
                type="button" 
                onClick={() => {
                  if (window.confirm('Delete all imported Coinbase transactions?')) {
                    props.deleteTransactions('cb');
                    props.close();
                  }
              }}>Delete All Coinbase data</button>
          </div>
      </div>
          </div>
      : importType === 'explorer:solscan' ?
        <Form.Group>
          <h2>Import from Solscan</h2>
          <Form.Label>Download a <a href="https://docs.solscan.io/solscan-tool/download-csv-report" target="_blank">Solscan report</a> as a CSV file and upload it here. 
          This upload assumes the file name contains one of your Solana wallet addresses.</Form.Label>
          <Form.Control type="file" onChange={(e) => {
            const file: File = (e.target as any)?.files?.[0];
            if (file != null) {
              const reader = new FileReader();
              reader.readAsText(file, "UTF-8");
              reader.onload = (value) => {
                const result = solscanCsvToRows(reader.result as string);
                const walletAddress = props.lastTsForWallets.find((wlt) => 
                  wlt.wallet.chain === ChainID.SOL && file.name.indexOf(wlt.wallet.address) >= 0)?.wallet.address ?? null;
                if (walletAddress == null) {
                  setErrorMessage('Unable to find one of your active wallet addresses in the file name');
                  return;
                }
                const lastImportedTs = props.lastTsForWallets.find(
                  wlt => wlt.wallet.chain === ChainID.SOL && wlt.wallet.address === walletAddress)?.ts ?? -1;
                if (result.parsingErrors.length > 0) {
                  setErrorMessage(result.parsingErrors.join(', '));
                  return;
                }
                const timestampColumn = 3;
                const txs = result.rows.filter(row => {
                  return lastImportedTs == null || 
                    new Date(row[timestampColumn]).getTime() > lastImportedTs
                });
                txs.sort((tx1, tx2) => new Date(tx1[timestampColumn]).getTime() - new Date(tx2[timestampColumn]).getTime())
                setSolscanWallet(walletAddress);
                setTransactions(txs);
                setStatusMessage(`Read ${txs.length} transactions for wallet ${walletAddress} after ${new Date(lastImportedTs)}`);
              };
              reader.onerror = (value) => {
                setSolscanWallet(undefined);
                setImportedFileName('');
                setStatusMessage(undefined);
                setTransactions(undefined);
                setErrorMessage(errorMessage);
              }
            }
          }}/>
        </Form.Group>
      : <></>}
      {errorMessage != null ? <div className="text-danger">Read error: {errorMessage}</div> : <></>}
      {statusMessage != null ? <div>{statusMessage}</div> : <></>}  
      <div className="mt-3">
      <button className="btn btn-primary" 
          type="button"
          disabled={transactions == null || transactions.length == 0}
          onClick={() => {
            if (importType === 'custodian:cb') {
              props.addCustodialTransactions('cb', importedFileName, {
                version: custodian.csvFormat.version,
                rows: transactions!
              });
            } else if (importType === 'explorer:solscan') {
              props.addSolscanTransactions({
                wallet: solscanWallet!, 
                columns: solscanColumns,
                rows: transactions!
              });
            }
            props.close();
        }}>Import</button>
      </div> 
    </Form>
    <div className="d-flex flex-row justify-content-end mt-3">
        <button type="button" className="btn btn-secondary" onClick={props.close}>Close</button>
      </div>
  </div>
}

export default ImportFileView;