import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { EventRow } from '../types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
      {
        data: labels.map(() => (Math.random() - 0.5)*1000),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };
  
export const ResultChart = (props: {
    dailyResults?: {
      lastDate: string;
      results: Array<number>;
    }
}) => {
    let d = new Date();
    const dateResults: Array<[string, number]> = [];
    let resultIndex: number | undefined = undefined;
    for (let i = 0; i < 365; i++) {
      let dailyResult = 0;
      const dateString = d.toISOString().substring(0, 10);
      if (props.dailyResults != null) {
        if (resultIndex == null && (props.dailyResults.lastDate == dateString)) {
          resultIndex = props.dailyResults.results.length - 1;
        }
        if (resultIndex != null && resultIndex >= 0) {
          dailyResult = props.dailyResults.results[resultIndex];
          resultIndex -= 1;
        } 
      }
      dateResults.push([dateString, dailyResult]);
      d = new Date(d.getTime() - 24*60*60*1000);
    }
    dateResults.reverse();
    let cumulativeResult = 0;
    for (let i = 0; i < dateResults.length; i++) {
      cumulativeResult += dateResults[i][1];
      dateResults[i][1] = cumulativeResult;
    }

    return <Line options={{
            scales: {
                x: {display: false}
            },
            elements: {
                point: {
                    radius: 1,
                    pointStyle: 'line'
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                    position: 'top' as const,
                },
                title: {
                display: false,
                text: '1 Year',
                }
            }
            }} 
        data={{
            labels: dateResults.map(([date, value]) => date),
            datasets: [{
                data: dateResults.map(([date, value]) => value), 
                borderColor: '#0681B1',
                backgroundColor: 'rgba(6, 143, 177, 0.5)'
        }]}} 
        style={{height: '100%'}} 
        className="mb-3"/>;
}