import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { prettyPrintTransactionId } from "../functions";
import { AccountApi } from "../net/AccountApi";
import { Account, Assets, Balance, EventRow, Event, EventType } from "../types";
import OverviewCardsView from "./OverviewCardsView";
import TransactionsView from "./TransactionsView";

function AccountOverviewView(props: {
    accountApi: AccountApi;
    page: number;
    filter?: string;
    accountAndPageEvents: {
      account: Account;
      events: Array<EventRow>;
      pages: number;
    };
    setPageSettings: (page: number, filter?: string) => void;
    nextSyncTs: number;
    syncWallets: () => void;
    mergeWith?: {txId: string, otherSideFilter: (event: Event) => boolean, candidateId?: string};
    setMergeWith: (mergeWith?: {txId: string, otherSideFilter: (event: Event) => boolean, candidateId?: string}) => void;
    performMerge: (mergePair: Array<string>)=> void;
    splitMerged: (txId: string) => void;
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchPage, setSearchPage] = useState(props.page);
    const [searchFilter, setSearchFilter] = useState(props.filter);
    const [syncEnabled, setSyncEnabled] = useState(true);
    const needSpecification = useMemo(() => {
      return props.accountAndPageEvents.account.needsSpecifyingCount - 
        Object.keys(props.accountAndPageEvents.account.specifiers).length;
    }, [props.accountAndPageEvents]);

    useEffect(() => {
      const remaining = props.nextSyncTs - Date.now();
      if (remaining >= 0) {
        setSyncEnabled(false);
        setTimeout(() => { setSyncEnabled(true) }, remaining);
      }
    }, [props.nextSyncTs]);

    useEffect(() => {
      const p = searchParams.get('page');
      const filter = searchParams.get('filter') ?? undefined;
      const newPage = p != null ? parseInt(p) : 1;
      if (newPage !== props.page || filter != props.filter) {
        props.setPageSettings(newPage, filter);
      }
    }, [searchParams]);

    useEffect(() => {
      const params = [];
      if (searchPage != 1) {
        params.push('page=' + searchPage);
      }
      if (searchFilter != null) {
        params.push('filter=' + searchFilter);
      }
      setSearchParams(params.join('&'));
    }, [searchPage, searchFilter]);

    const transactionByDateDesc = useMemo<Array<{date: string, events: Array<EventRow>}>>(() => {
        const t = Date.now();
        const txByDate = new Map<string, Array<EventRow>>();
        if (props.accountAndPageEvents.account != null) {
          props.accountAndPageEvents.events.forEach(row => {
            let rows = txByDate.get(row.date);
            if (rows == null) {
              rows = [];
              txByDate.set(row.date, rows);
            }
            rows.push(row);
          });
        }
        const sorted = Array.from(txByDate.keys()).sort().reverse().map((date) => {
          return {
            date,
            events: txByDate.get(date) ?? []
          };
        });
        return sorted;
      }, [props.accountAndPageEvents.events]);

    return <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-2">
        <div className="d-flex flex-column flex-lg-row align-items-baseline">
          <legend className="flex-shrink-1 mb-0 me-3">{`${props.accountAndPageEvents.account.name}`}</legend>
          <OverlayTrigger key='bottom'
            placement='bottom'
            overlay={
              <Tooltip id={`tooltip-transactions`}>
                <strong>Source transactions</strong> are transactions returned by each data source. A blockchain transaction
                may result in multiple source transactions.
              </Tooltip>
            }><small className="text-nowrap">
            {`${props.accountAndPageEvents.account.txCount}/${props.accountAndPageEvents.account.txLimit} source transactions`}</small>
          </OverlayTrigger>
        </div>
        <div className="d-flex flex-row flex-shrink-0 align-items-center">
          {needSpecification ? <div className="me-3 d-flex flex-row align-items-center">
            <FontAwesomeIcon size="xl" icon={["fas", "warning"]} className="me-1"/>
            <div><small>{needSpecification+ ' rows need specification'}</small></div>
          </div> : <></>}
          <button className="btn btn-outline-dark text-nowrap" 
            disabled={!syncEnabled}
            onClick={props.syncWallets}>
            <FontAwesomeIcon icon={["fas", "rotate"]}/>{syncEnabled ? ' Sync Wallets' : ' Enabled ' + new Date(props.nextSyncTs).toTimeString().substring(0, 8)}
          </button>
        </div>
      </div>
      <OverviewCardsView 
                  currency={props.accountAndPageEvents.account.currencyName}
                  assets={props.accountAndPageEvents.account.assets}
                  balances={props.accountAndPageEvents.account.balances}
                  dailyResults={props.accountAndPageEvents.account.dailyResults} 
                  hasSpecifiers={props.accountAndPageEvents.account.specifiers.length > 0}
                  hasImportedTransactions={Object.entries(props.accountAndPageEvents.account.lastImportedTransactions).length > 0}
                  result={{
                    trading: props.accountAndPageEvents.account.result.trading,
                    income: props.accountAndPageEvents.account.result.income,
                    yield: props.accountAndPageEvents.account.result.yield
                  }}
                  />
      <Form className="w-100 d-flex flex-row mt-2">
        <small className="d-flex flex-row">
          <b>Filter transactions:</b>
          <Form.Check className="ms-3" label=" Manual specification" 
            checked={searchParams.get('filter') != null} 
            onChange={(e) => {
              setSearchFilter(e.currentTarget.checked ? 'spec': undefined);
              setSearchPage(1);
            }}/>
        </small>
      </Form>
      <TransactionsView 
        assets={props.accountAndPageEvents.account.assets}
        specifiers={new Map(props.accountAndPageEvents.account.specifiers.map(({id, specifier: specification}) => [id, specification]))}
        transactionsByDate={transactionByDateDesc} 
        mergeWith={props.mergeWith}
        startMerge={(txId, otherSideFilter) => {
          props.setMergeWith({txId, otherSideFilter});
        }}
        onMergeCandidateChange={(candidateId) => {
          props.setMergeWith(props.mergeWith != null ? {
              txId: props.mergeWith.txId, 
              otherSideFilter: props.mergeWith.otherSideFilter,
              candidateId: props.mergeWith.candidateId === candidateId ? undefined : candidateId
            } : undefined);
        }}
        splitMerged={props.splitMerged}
        />
      {props.accountAndPageEvents.account.pages === 1 ? <></>: 
        <div className="w-100 d-flex flex-row justify-content-center flex-wrap">
            {Array(props.accountAndPageEvents.pages).fill(0).map((_ignored, p) => {
              if (p + 1 === props.page) {
                return <span className="me-3" key={p}>{p+1}</span>;
              } else {
                return <a className="me-3" key={p} onClick={() => {setSearchPage(p+1);}}>{p+1}</a>;
              }
            })}
        </div>}
      {props.mergeWith != null ? <>
      <div style={{minHeight: '100px'}}/>
      <div className="fixed-bottom card d-flex flex-column align-items-center p-3">
        <div className="d-flex flex-column">
          <h5>Merge</h5>
          <p>Select related Send and a Receive transactions to merge into a Transfer transaction.</p>
          <p>{`Merge ${prettyPrintTransactionId(props.mergeWith.txId)} with
          ${props.mergeWith.candidateId ? prettyPrintTransactionId(props.mergeWith.candidateId): '<None selected>'}`}</p>
          <div className="align-self-end">
            <button className="btn btn-secondary me-3" type="button" onClick={() => {props.setMergeWith(undefined);}}>Cancel</button>
            <button className="btn btn-primary" type="button" 
              disabled={props.mergeWith.candidateId == null} 
              onClick={() => {
                props.performMerge([props.mergeWith!.txId, props.mergeWith!.candidateId!]);
              }}>Merge</button>
          </div>
        </div>
      </div>
      </> : <></>}
    </div>;
}

export default AccountOverviewView;