import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, Spinner, } from "react-bootstrap";
import { AccountApi } from "../net/AccountApi";
import { Globals } from "../net/internal";

const years = (() => {
  const years = [];
  const start = new Date().getFullYear();
  for (let i = 0; start  - i > 2010; i++) {
    years.push(start - i);
  }
  return years;
})();

const timezone = (() => {
  const tz = [];
  for (let i = 0; i < 23; i++) {
    const h = Math.abs((i-11)).toString();
    let hpad = h.length == 2 ? h : '0' + h;
    const prefix = i-11 >= 0 ? '+' : '-';
    if (prefix === '-') {
      tz.push(`${prefix}${hpad}:30`);
      tz.push(`${prefix}${hpad}:00`);
    } else {
      tz.push(`${prefix}${hpad}:00`);
      tz.push(`${prefix}${hpad}:30`);
    }
  }
  return tz;
})();

const currentTz = (() => {
  const h = (-new Date().getTimezoneOffset())/60;
  let hpad = h.toString().length == 2 ? h.toString() : '0' + h.toString();
  const min = (-new Date().getTimezoneOffset()) - h*60;
  const minPad = min.toString().length == 2 ? min.toString() : '0' + min.toString();
  return `+${hpad}:${minPad}`
})();

const ReportButtons = (props: {
  reportTypeName: string;
  existingUrl: string | null; 
  generatingMsg: string | null;
  generateFn: () => void;
  deleteFn: (reportName: string) => void;
}) => {
  return  <div className="mb-3">
    {props.existingUrl == null ?
      <Button type="button" 
        onClick={props.generateFn}
        disabled={props.generatingMsg != null}>{props.generatingMsg ? <span><Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />{' ' + props.generatingMsg}</span> : 'Generate ' + props.reportTypeName}</Button>
        : 
        <div className="d-flex flex-row">
          <a className="btn btn-primary" 
            href={props.existingUrl} 
            target="_blank">Download
          </a>
          <Button type="button" 
            className="ms-3"
            onClick={() => props.deleteFn(props.existingUrl!.substring(props.existingUrl!.lastIndexOf('/')+1))}
            disabled={props.generatingMsg != null}>{props.generatingMsg ? <span><Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' ' + props.generatingMsg}</span> : 'Delete Report'}</Button>
        </div>
      }
  </div>;
}

function ReportsView(props: {
  accountApi: AccountApi,
  close: () => void;
}) {
  const [reports, setReports] = useState<Array<{name: string, url: string}>>();
  const [generating, setGenerating] = useState<string | null>(null);
  const [generatedCsvUrl, setGeneratedCsvUrl] = useState<string | null>(null);
  const [csvYear, setCsvYear] = useState(2022);// new Date().getFullYear() - 1);
  const [csvTz, setCsvTz] = useState(currentTz);
  const [generatedK4Url, setGeneratedK4Url] = useState<string | null>(null);
  const [k4Year, setK4Year] = useState(new Date().getFullYear() - 1);
  const [k4personnummer, setk4personnummer] = useState('');
  const [k4namn, setk4namn] = useState('');
  const reloadReports = () => {
    setGenerating('Updating...');
    props.accountApi.listReports((reportNames) => {
      setReports(reportNames.map(name => {
        const url = Globals.API_URL + '/account/' + props.accountApi.accountId + '/report/' + name;
        return {
          name,
          url
        };
      }));
      setGenerating(null);
    }, () => setGenerating(null));
  };
  useEffect(() => {
    reloadReports();
  }, [props.accountApi]);
  useEffect(() => {
    const csvReport = reports?.find((r) =>r.name.startsWith(`sales_${csvYear}`));
    const k4report = reports?.find((r) =>r.name.startsWith(`k4`));
    setGeneratedCsvUrl(csvReport?.url ?? null);
    setGeneratedK4Url(k4report?.url ?? null);
  }, [reports, csvYear]);
  return <Form className="d-flex flex-column col-lg-6">
          <legend>Reports</legend>
          <Form.Text className="mb-3"> 
            Web3Gains populates reports with data as calculated in the transaction view and does not provide tax advice. You are
            responsible for determining whether calculations in reports are compatible with your local tax regulations.
          </Form.Text>
          <Form.Text className="mb-3 text-danger">
            Reports may be limited to the first transactions in the free plan!
          </Form.Text>
          <h2>Asset Sales Totals</h2>
          <Form.Text> 
            Reports with totals for profits and losses for each asset sold.
          </Form.Text>
          <h4 className="mt-3">CSV File</h4>
          <Form.Group className="mb-3 d-flex flex-row align-items-center justify-content-evenly" controlId="personnummer">
              <div className="d-flex flex-row align-items-baseline">
                <Form.Label className="me-1 flex-shrink-0">Generate for year</Form.Label>
                <div>
                <Form.Select disabled={false} value={csvYear} onChange={(e) => setCsvYear(parseInt(e.currentTarget.value))}>
                  {years.map((y) => <option key={y.toString()} value={y}>{y}</option>)}
                </Form.Select>
                </div>
              </div>
              <div className="d-flex flex-row flex-grow-1 align-items-baseline">
              <Form.Label className="ms-3 me-1 flex-shrink-0">Timezone (GMT+/-)</Form.Label>
              <div>
              <Form.Select value={csvTz} onChange={(e) => setCsvTz(e.currentTarget.value)}>
                {timezone.map((tz) => <option key={tz.toString()} value={tz}>{tz}</option>)}
              </Form.Select>
              </div>
              </div>
          </Form.Group>
          <ReportButtons
            reportTypeName="CSV Report"
            existingUrl={generatedCsvUrl}
            generatingMsg={generating}
            generateFn={() => {
              setGenerating('Generating...');
              props.accountApi.generateCsv(csvYear, csvTz, ({}) => {
                reloadReports();
              }, () => {
                setGenerating(null);
              });
            }}
            deleteFn={(reportName: string) => {
              setGenerating('Deleting...');
              props.accountApi.deleteReport(reportName, () => {
                reloadReports();
              }, () => {
                setGenerating(null);
              });
            }}
            />
          <h4 className="mt-2">Swedish K4 tax form</h4>
            <Form.Text className="mb-3">
            Personal data is only used to populate the form. To keep personal data out of your account, delete the 
            report after downloading it or leave the fields blank and fill them out manually.
          </Form.Text>
          <Form.Group className="mb-3 d-flex flex-row align-items-baseline" controlId="personnummer">
              <Form.Label className="me-3 col-lg-3">Generate for year</Form.Label>
              <Form.Select value={2023} onChange={(e) => setK4Year(parseInt(e.currentTarget.value))} disabled={false}>
                {[2022, 2023].map((y) => <option key={y.toString()} value={y}>{y}</option>)}
              </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3 d-flex flex-row align-items-baseline" controlId="personnummer">
              <Form.Label className="me-3  col-lg-3">Personnummer</Form.Label>
              <Form.Control placeholder="yyyymmdd-xxxx"
                value={k4personnummer} 
                onChange={(e) => setk4personnummer(e.currentTarget.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3 d-flex flex-row align-items-baseline" controlId="name">
              <Form.Label className="me-3  col-lg-3">Namn</Form.Label>
              <Form.Control placeholder='Förnamn Efternamn' 
                value={k4namn} 
                onChange={(e) => setk4namn(e.currentTarget.value)}></Form.Control>
          </Form.Group>
          <ReportButtons
            reportTypeName="K4 Report"
            existingUrl={generatedK4Url}
            generatingMsg={generating}
            generateFn={() => {
              setGenerating('Generating...');
              props.accountApi.generateK4(k4Year, k4personnummer, k4namn, () => {
                reloadReports();
              }, () => {
                setGenerating(null);
              });
            }}
            deleteFn={(reportName: string) => {
              setGenerating('Deleting...');
              props.accountApi.deleteReport(reportName, () => {
                reloadReports();
              }, () => {
                setGenerating(null);
              });
            }}
            />
          <div className="d-flex flex-row justify-content-end mt-3">
            <Button type="button" variant="secondary" onClick={props.close}>Close</Button>
          </div>
        </Form>;
}

export default ReportsView;