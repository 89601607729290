export const ACCOUNT_LIMITS = {
  maxAccounts: 1,
  maxWallets: 20
};

export enum Currency {
 EUR,
 USD,
 SEK,
 CAD,
 GBP,
 AUD,
 JPY,
 CHF,
 DKK,
 NOK,
 PHP,
 THB,
 HKD,
 ISK,
 IDR,
 TRY,
 RON,
 ILS,
 CNY,
 HRK,
 INR,
 HUF,
 MXN,
 KRW,
 BGN,
 ZAR,
 NZD,
 PLN,
 MYR,
 SGD,
 CZK,
 BRL
}

export type AssetID = string;

export interface NotReadyRsp {
  notReady: true
};

export interface Assets {
  [key: AssetID]: {code: string}
}

export interface AssetQuantity {
  asset: AssetID;
  quantity: string;
}

export enum ExchangeRateSource {
  ECB = 'ecb',
  Coingecko = 'cg',
  Coinbase = 'cb',
  Coinlore = 'cl'
}

export interface ExchangeRate {
  ts: number;
  from: AssetID;
  to: AssetID;
  rate: string;
  source: ExchangeRateSource;
}

export enum ChainID {
  ETH = 'ETH',
  SOL = 'SOL'
}

export interface Wallet {
  chain: ChainID;
  address: string;
}

export interface Account {
  name: string,
  nextSyncTs: number;
  currencyName: string;
  result: {
    trading: string;
    income: string;
    yield: string;
  }
  assets: Assets;
  balances: Array<Balance>;
  events: Array<EventRow>;
  page: number;
  pages: number;
  wallets: Array<Wallet>;
  walletBalances: {[key in string]: AssetQuantity};
  custodians: Array<Custodian>;
  lastImportedTransactions: Array<{
    custodian: string;
    ts: number;
  }>;
  lastTsForWallets: Array<{
    wallet: Wallet;
    ts: number;
  }>;
  specifiers: Array<{
    id: string;
    specifier: EventSpecifier
  }>,
  dailyResults?: {
    lastDate: string;
    results: Array<number>;
  },
  needsSpecifyingCount: number;
  txCount: number;
  txLimit: number;
}

export enum EventType {
  Unknown,
  Send,
  Receive,
  Buy,        // Fiat Buy
  Sell,       // Fiat sell
  Transfer,
  Convert,    // Conversions between crypto
  Error,
  Invocation, // Zero-value contract interactions
  Earnings
}

export enum EarningsType {
  Unknown,
  Yield,
  Spam,
  Income
}

export enum SpecifyOptions {
  Receive,
  Send,
  ZeroPriceConvert
}

export interface Event {
  id: string;
  mergeId?: string;  // If merged with custodial event
  ts: number;
  type: EventType;
  specifyOptions?: SpecifyOptions,
  earningsType?: EarningsType;
  typeDesc?: string;
  hasInput?: boolean;
  quantity: Array<AssetQuantity>;
  price: Array<AssetQuantity>;
  fees: Array<AssetQuantity>;
  unspecified?: Pick<Event, 'type' | 'earningsType' | 'price'>;
  rates?: Array<ExchangeRate>;
  from?: string;
  to?: Array<string>;
}

export interface Custodian {
  id: string;
  name: string;
  csvFormat: {
    version: number;
    columns: Array<string>;
    timestampColumnIndex: number;
  }
}

export interface EventSpecifier {
  type: EventType;
  earningsType?: EarningsType;
  price?: Array<AssetQuantity>;
}

export interface CustodialTransactionData {
  ts: number;
  custodian: string;
  kind: EventType;
  earningType?: EarningsType,
  typeDesc?: string;
  quantity: AssetQuantity;
  price?: AssetQuantity;
  fee?: AssetQuantity;
  address?: string;
}

export interface Balance {
  quantity: AssetQuantity;
  costBasis?: AssetQuantity;
}

export interface EventRow {
  id: string;
  date: string;
  event: Event;
  balances: Array<Balance>;
  result: {
    value?: string;
    details: string;
  };
  fees: string;
}