import { Account, CustodialTransactionData, EventRow, Event, EventSpecifier, NotReadyRsp } from "../types";
import { HttpMethod, sendFetch } from "./internal";

export class AccountApi {
  private updateListener?: (updateStatus?: string) => void;
  private completionHandler = () => this.updateListener?.(undefined);
  readonly rootPath: string;

  constructor(readonly accountId: string, private readonly errorHandler: (err: string) => void) {
    this.rootPath = '/account/' + accountId
  } 

  public setUpdateListener(updateListener: (updateStatus?: string) => void) {
    this.updateListener = updateListener;
  }

  public getAccount(syncWallets: boolean, successHandler: (account: Account) => void) {
    this.updateListener?.('Loading account information. May take several minutes if there is lots of new information.');
    const longPollHandler = (rsp: Account | NotReadyRsp) => {
      if ('notReady' in rsp) {
        sendFetch(HttpMethod.GET, this.rootPath + (syncWallets ? '?sync=true' : ''), null, longPollHandler, (err) => {
          this.completionHandler();
          this.errorHandler(err);
        });
      } else {
        this.completionHandler();
        successHandler(rsp);
      }
    };
    longPollHandler({notReady: true});
  }

  public getEvents(query: {page: number, filter?: string}, successHandler: (rsp: {events: Array<EventRow>, pages: number}) => void) {
    this.updateListener?.('Loading account information. May take several minutes if there is lots of new information.');
    const longPollHandler = (rsp: {events: Array<EventRow>, pages: number} | NotReadyRsp) => {
      if ('notReady' in rsp) {
        sendFetch(HttpMethod.GET, 
          this.rootPath + '/events?page=' + query.page + (query.filter == null ? '' : '&filter=' + query.filter), 
          null, 
          longPollHandler, 
          (err) => {
            this.completionHandler();
            this.errorHandler(err);
          });
      } else {
        this.completionHandler();
        successHandler(rsp);
      }
    };
    longPollHandler({notReady: true});
  }

  public getEvent(eventId: string, successHandler: (event: Event) => void) {
      sendFetch(HttpMethod.GET, this.rootPath + '/events/' + eventId, null, successHandler, this.errorHandler);
  }

  public listReports(successHandler: (reports: Array<string>) => void, errorCallback: () => void) {
    sendFetch(HttpMethod.GET, this.rootPath + '/report', null, 
      (rsp: {fileNames: Array<string>}) => successHandler(rsp.fileNames), (err) => {
      errorCallback();
      this.errorHandler(err);
    });;
  }

  public generateCsv(year: number, timezone: string, successHandler: (reportName: string) => void, errorCallback: () => void) {
    sendFetch(HttpMethod.POST, this.rootPath + '/report', {
      format: 'csv',
      fields: {
        year,
        timezone
      }
    }, successHandler, (err) => {
      errorCallback();
      this.errorHandler(err);
    });
  }

  public generateK4(year: number, k4personnummer: string, k4namn: string, successHandler: (reportName: string) => void, errorCallback: () => void) {
    sendFetch(HttpMethod.POST, this.rootPath + '/report', {
      format: 'k4',
      fields: {
        year,
        personnummer: k4personnummer,
        namn: k4namn
      }
    }, successHandler, (err) => {
      errorCallback();
      this.errorHandler(err);
    });
  }

  deleteReport(name: string, successHandler: () => void, errorCallback: () => void): void {
    sendFetch(HttpMethod.DELETE, this.rootPath + '/report/' + name, null, successHandler, (err) => {
      errorCallback();
      this.errorHandler(err);
    });
  }

  public updateWallets(ethWallets: Array<string>, solWallets: Array<string>, successHandler: () => void) {
    this.updateListener?.('Updating wallets');
    sendFetch(HttpMethod.PUT, this.rootPath + '/wallets', {ethWallets, solWallets}, successHandler, this.errorHandler, this.completionHandler);
  }

  public mergeTransactions(idPairs: Array<string>, successHandler: () => void) {
    this.updateListener?.('Merging transactions');
    sendFetch(HttpMethod.POST, this.rootPath + '/merge', { idPairs: idPairs }, successHandler, this.errorHandler, this.completionHandler);
  }

  public splitMerged(id: string, successHandler: () => void) {
    this.updateListener?.('Splitting merged transactions');
    sendFetch(HttpMethod.DELETE, this.rootPath + '/merge', { id }, successHandler, this.errorHandler, this.completionHandler);
  }

  public specifyEvent(id: string, data: EventSpecifier, successHandler: () => void) {
    this.updateListener?.('Specifying transaction');
    sendFetch(HttpMethod.POST, this.rootPath + '/specify', { id, data }, successHandler, this.errorHandler, this.completionHandler);
  }

  public unspecifyEvent(id: string, successHandler: () => void) {
    this.updateListener?.('Specifying transaction');
    sendFetch(HttpMethod.DELETE, this.rootPath + '/specify/' +id, null, successHandler, this.errorHandler, this.completionHandler);
  }

  public addCustodialTransactions(custodianId: string, importedFileName: string, version: number, rows: Array<Array<string>>, successHandler: () => void) {
    this.updateListener?.('Sending custodial transactions');
    sendFetch(HttpMethod.POST, 
      this.rootPath + '/custodial/' + custodianId, 
      { 
        source: importedFileName,
        version,
        rows
      }, 
      successHandler, 
      this.errorHandler,
      this.completionHandler);
  }

  addSolscanTransactions(transactions: { wallet: string; columns: Array<string>; rows: string[][]; }, successHandler: () => void) {
    this.updateListener?.('Sending Solscan transactions');
    let i = -1;
    const sendNext = () => {
      i++;
      console.log(`Sending rows ${i*100} to ${Math.min((i+1)*100, transactions.rows.length)} of Solscan transactions`)
      sendFetch(HttpMethod.POST, 
        this.rootPath + '/solscan', 
        { 
          wallet: transactions.wallet,
          columns: transactions.columns,
          rows: transactions.rows.slice(i*100, Math.min((i+1)*100, transactions.rows.length))
        }, 
        ((i+1) * 100) < transactions.rows.length ? sendNext : successHandler, 
        this.errorHandler,
        this.completionHandler);
    }
    sendNext();
  }

  public deleteCustodialTransactions(custodianId: string, successHandler: () => void) {
    this.updateListener?.('Deleting custodial transactions');
    sendFetch(HttpMethod.DELETE, 
      this.rootPath + '/custodial/' + custodianId, 
      null, 
      successHandler, 
      this.errorHandler,
      this.completionHandler);
  }
}