import { AccountApi } from "./AccountApi";
import { HttpMethod, sendFetch } from "./internal";

export interface GetUserResult {
  name: string,
  email: string
}

// API with functions that authenticated users can perform
export class Api {

  private accountApi?: AccountApi;

  constructor(private readonly errorHandler: (err: string) => void = (err) => {console.log('API error: ' + err)}) {
  }

  public createAccount(name: string, currency: string, ethWallets: Array<string>, solWallets: Array<string>, successHandler: (rsp: {id: string}) => void) {
    sendFetch(HttpMethod.POST, '/account', {
      name,
      currency,
      ethWallets,
      solWallets
    }, successHandler, this.errorHandler);
  }

  public listAccounts(successHandler: (accountIds: Array<{id: string, name: string}>) => void) {
    sendFetch(HttpMethod.GET, '/account', null, successHandler, this.errorHandler);
  }

  public getAccountApi(accountId: string) {
    if (this.accountApi?.accountId !== accountId) {
      this.accountApi = new AccountApi(accountId, this.errorHandler);
    }
    return this.accountApi;
  }

  public getUser(successHandler: (user: GetUserResult) => void, errorHandler?: (err: string) => void): void {
    sendFetch(HttpMethod.GET, "/user", null, successHandler, errorHandler);
  }

  public changeEmailAddress(newEmail: string, successHandler: () => void) {
    sendFetch(HttpMethod.PUT, '/auth', {newEmail}, successHandler, this.errorHandler);
  }

  public signOut(successHandler: () => void)  {
    sendFetch(HttpMethod.DELETE, '/auth', null, successHandler, this.errorHandler);
  }
}
