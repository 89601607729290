import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Session } from "../net/Session";

/**
 * Shows the signed-in user or a link to sign in.
 */
export function StatusButton(props: {
  signedInUser?: string,
  signOut: () => void
}) {
  const navigate = useNavigate();
  return props.signedInUser != null ? 
    <DropdownButton className="m-2" menuVariant="light" variant="light" size="sm" id="user-dropdown-button" title={props.signedInUser}>
      <Dropdown.Item onClick={() => {
        props.signOut();
        navigate('/');
      }}>Log out</Dropdown.Item>
    </DropdownButton> : 
    <Link to="register">Sign in/Register</Link>;
}